import Link from 'next/link'

import Meta from 'components/partials/meta'
import Header from 'components/structure/header'
import Footer from 'components/structure/footer'
import Main from 'components/structure/main'

const Custom404 = () => {
  return (
    <>
      <Meta />
      <Header />
      <Main>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell">
              <div className="single-column-wrapper">
                <h1 className="section-header">404 - Page Not Found</h1>
                <p>The page you are looking for is not available or does not exist.</p>
                <p>Please check the web address and try again.</p>
                <p>
                  <Link href="/">
                    Click here to go back to the home page.
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Main>
      <Footer />
    </>
  )
}

export default Custom404